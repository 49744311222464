import { Globals } from '../data/Globals';
import { gsap } from 'gsap';

export class HandPoseDetection {
	private _predictions;
	private _predictionCount: number = 0;
	private _firstPrediction: boolean = false;
	private _successCallback: Function;
	private _userFeedVideo: HTMLVideoElement;

	private _canvasContainer: HTMLDivElement = document.createElement('div');

	private _canvasElement: HTMLCanvasElement = document.createElement('canvas');
	private _canvasCtx: CanvasRenderingContext2D = this._canvasElement.getContext('2d');

	private _previousTime: Number = -1;

	constructor(successCallback: Function, userFeedVideo) {
		this._successCallback = successCallback;
		this._userFeedVideo = userFeedVideo;
		this._canvasElement.width = Globals.VIDEO_WIDTH;
		this._canvasElement.height = Globals.VIDEO_HEIGHT;
		this.loadHandPose();

		this._canvasContainer.appendChild(this._canvasElement);
		document.body.appendChild(this._canvasContainer);

		var calculateScaleX = window.innerWidth / Globals.VIDEO_WIDTH;

		Globals.CANVAS_SCALE = calculateScaleX;

		gsap.set(this._canvasElement, { filter: 'contrast(150%) grayscale(100%) brightness(20%)' });
		this._canvasContainer.style.position = 'absolute';
		this._canvasContainer.style.zIndex = '-2';
		gsap.set(this._canvasContainer, { scale: calculateScaleX, force3D: true, transformOrigin: '0% 0%' });
	}

	private onResults = result => {
		if (this._predictionCount === 2) {
			this._firstPrediction = false;
			this._successCallback();
		}
		this._predictionCount++;

		var allowPredict = true;

		if (Globals.PAUSE_HAND_DETECTION === true) {
			allowPredict = false;
		}
		/*	if (Globals.ACTIVE_STATE_CONTROLLER.pageVisible === false) {
			allowPredict = false;
		}*/

		//console.log(result)

		this._canvasCtx.save();
		this._canvasCtx.clearRect(0, 0, this._canvasElement.width, this._canvasElement.height);
		this._canvasCtx.drawImage(result.image, 0, 0, this._canvasElement.width, this._canvasElement.height);
		if (result.multiHandLandmarks && result.multiHandedness) {
			Globals.LEFT_HAND_FOUND = true;
			var landMarks = result.multiHandLandmarks.length;
			for (let index = 0; index < landMarks; index++) {
				const classification = result.multiHandedness[index];
				const isRightHand = classification.label === 'Right';
				if (isRightHand === true || Globals.STANDALONE === true) {
					const landmarks = result.multiHandLandmarks[index];
					window.drawConnectors(this._canvasCtx, landmarks, window.HAND_CONNECTIONS, { color: '#aaaaaa' });
					window.drawLandmarks(this._canvasCtx, landmarks, {
						color: '#bbbbbb',
						fillColor: '#bbbbbb'
					});
				}
				if (isRightHand) {
					Globals.LEFT_HAND_FOUND = false;
					Globals.RIGHT_HAND_FOUND = true;
				}
			}
		} else {
			Globals.DOMCursor.noHandFound();
		}
		this._canvasCtx.restore();

		if (result.multiHandLandmarks && allowPredict === true && (Globals.RIGHT_HAND_FOUND || Globals.STANDALONE)) {
			var handLandmarks = result.multiHandLandmarks[0];

			//	console.log(this._predictions);
			// && this._predictions.handInViewConfidence > 0.8
			if (handLandmarks) {
				// Recalculate all landmarks to fit witnin the videos bounds
				var landmarks = handLandmarks;
				var length = landmarks.length;

				var newLandMarks = [];
				for (var i = 0; i < length; i++) {
					var currentLandmark = landmarks[i];
					var xPos = currentLandmark.x;
					var yPos = currentLandmark.y;
					newLandMarks.push([xPos, yPos]);
				}
				Globals.DOMCursor.handFound(newLandMarks, newLandMarks);
			} else {
				Globals.DOMCursor.noHandFound();
			}
		}

		var calculateScaleX = window.innerWidth / Globals.VIDEO_WIDTH;

		Globals.CANVAS_SCALE = calculateScaleX;
		gsap.set(this._canvasContainer, { scale: calculateScaleX, force3D: true, transformOrigin: '0% 0%' });
	};

	private loadHandPose = () => {
		console.log('loadHandPose');

		const hands = new Hands({
			locateFile: file => {
				return `https://cdn.jsdelivr.net/npm/@mediapipe/hands@0.1.1612238212/${file}`;
			}
		});
		hands.setOptions({ selfieMode: true, maxNumHands: 1, minDetectionConfidence: 0.8, minTrackingConfidence: 0.5 });
		hands.onResults(this.onResults);

		var userVideo = this._userFeedVideo;

		async function check() {
			await predict();
		}

		check();

		var previousTime = this._previousTime;

		async function predict() {
			Globals.MAIN.statsHandTracking.begin();
			if (userVideo.currentTime !== previousTime) {
				await hands.send({ image: userVideo });
			}
			previousTime = userVideo.currentTime;
			Globals.MAIN.statsHandTracking.end();
			requestAnimationFrame(predict);

			return;
		}
	};
}
