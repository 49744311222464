import { Back, Linear, Power1, Power2, gsap } from 'gsap';
import { Globals } from '../data/Globals';

export class SetupVideo {
	private _userFeedVideo: HTMLVideoElement;
	private _videoContainer: HTMLDivElement;

	private _successCallback: Function;

	constructor(successCallback: Function) {
		this._successCallback = successCallback;
		this.setupVideo();
	}

	private setupVideo = () => {
		Globals.VIDEO_SETUP = true;
		this._userFeedVideo = document.querySelector('#userFeedVideo') as HTMLVideoElement;
		this._userFeedVideo.addEventListener('loadeddata', this.videoStarted);
		this._videoContainer = document.querySelector('#videoContainer') as HTMLDivElement;
		Globals.USER_VIDEO_CONTAINER = this._videoContainer;

		var video = this._userFeedVideo;

		let options = { audio: false, video: true, facingMode: 'user', width: { ideal: Globals.VIDEO_WIDTH }, height: { ideal: Globals.VIDEO_HEIGHT } };

		if (navigator.mediaDevices.getUserMedia) {
			navigator.mediaDevices
				.getUserMedia({
					audio: false,
					video: options
				})
				.then(function(stream) {
					video.srcObject = stream;
					let promise = video.play();

					if (promise !== undefined) {
						promise
							.then(() => {})
							.catch(error => {
								console.log(error);
								//	alert('Error: Camera not allowed');
							});
					}
				})
				.catch(function(error) {
					console.log(error);
					alert(
						"Unfortunately we could not access your camera (either you clicked disallow or the camera wasn't found) - check your camera settings by clicking the camera icon in the browserbar. And reload the page."
					);
					//alert('Error: Camera not allowed');
				});
		}
	};

	private videoStarted = event => {
		this.cameraAccessGiven();
	};

	private cameraAccessGiven = () => {
		this._successCallback();
		//	this.startDetection();
	};

	public getVideoFeed = () => {
		return this._userFeedVideo;
	};
}
