import { AbstractView } from '../core/AbstractView';
import { Globals } from '../../data/Globals';
import { gsap } from 'gsap';
import { UIManager } from '../../utils/UIManager';
import Global = WebAssembly.Global;
import { DrawingAppDetailed } from '../../utils/DrawingAppDetailed';

export class DrawingView extends AbstractView {
	private _introSection: HTMLDivElement;
	private _demoSection: HTMLDivElement;
	private _drawingApp: DrawingAppDetailed;
	private _uiManager: UIManager;

	private _fingerThumb: HTMLDivElement;
	private _fingerIndex: HTMLDivElement;
	private _fingerMiddle: HTMLDivElement;
	private _fingerRing: HTMLDivElement;
	private _fingerPinky: HTMLDivElement;

	constructor(element: HTMLElement, name: string, initial: boolean = false) {
		super(element, name, initial);

		this._introSection = this.element.querySelector('.introScreen');
		this._demoSection = this.element.querySelector('.demoScreen');

		this._uiManager = new UIManager(this.element);

		if (Globals.STANDALONE) {
			this.removeIntroScreen();
			this.touchCardCallback('startDemo');
		}

		gsap.set(this.element, { y: -window.innerHeight });
	}

	public in = () => {
		Globals.ACTIVE_VIEW = this;
		Globals.VIEW_MANAGER.addView(this.element);
		gsap.to(this.element, 0.5, { y: 0, ease: 'power1.out', onComplete: this.animatedIn });
		if (Globals.STANDALONE !== true) {
			Globals.MAIN.showBackToDemos();
		}
	};

	private animatedIn = () => {
		Globals.PAUSE_HAND_DETECTION = false;
		this._uiManager = new UIManager(this.element);
		this.inComplete();
	};

	public touchCardCallback = actionType => {
		if (actionType === 'startDemo') {
			gsap.to(this._introSection, 0.3, { autoAlpha: 0, onComplete: this.removeIntroScreen });
			this._demoSection.style.display = 'block';
			this._demoSection.style.opacity = '0';
			gsap.to(this._demoSection, 0.3, { autoAlpha: 1 });
			this._uiManager.resize();
			this._drawingApp = new DrawingAppDetailed(this._demoSection);
		} else if (actionType === 'clear') {
			this.clearDrawing();
		} else if (actionType === 'colorBlue') {
			this._drawingApp.setColor('#3b76e8');
		} else if (actionType === 'colorGold') {
			this._drawingApp.setColor('#ddc676');
		}
	};

	private removeIntroScreen = () => {
		this._introSection.style.display = 'none';
		this._uiManager.resize();
	};

	private clearDrawing = () => {
		this._drawingApp.clear();
	};

	public handLost = () => {};

	public onRequestAnimationFrame = () => {
		if (this._uiManager) {
			this._uiManager.mouseMoved();
		}

		if (Globals.PINCH_DISTANCE < Globals.PINCH_DISTANCE_FOR_CLICK && this._drawingApp) {
			this._drawingApp._stroke(Globals.DOMCursor.pos.x, Globals.DOMCursor.pos.y);
			this._drawingApp._continueStroke(Globals.DOMCursor.pos.x, Globals.DOMCursor.pos.y);
			/*	var useFingers = [this._fingerThumb, this._fingerIndex, this._fingerMiddle, this._fingerRing, this._fingerPinky];

			for (var i = 1; i < 2; i++) {
				var currentFinger = useFingers[i];
				var indexPos = { x: Number(gsap.getProperty(currentFinger, 'x')), y: Number(gsap.getProperty(currentFinger, 'y')) };

				var scale = Number(gsap.getProperty(currentFinger, 'scale'));
				if (Globals.DOMCursor._fingerPoseResults) {
					var getFingerPose = Globals.DOMCursor._fingerPoseResults.curls[i];
*/
			/*	var setScale = (getFingerPose.angle - 100) / 10;
					if (setScale < 0) {
						setScale = 0.00001;
					}*/
			//	var setScale = 7

			//	gsap.to(currentFinger, 0.1, { scale: setScale / 7, transformOrigin: '0px 0px' });
			//	scale = setScale / 5;
			//	}
			//this._drawingApp.touchMoved(i, indexPos.x, indexPos.y, scale);
			//	}*/
		} else {
			this._drawingApp._endStroke();
		}
	};

	public reactToDOMCursor = (fingerPoseResults, result) => {
		// Move finger tips

		var isPalmPointing = Globals.HAND_ANALYZER.isRightHandPalmPointingTowardsYourself(result);
		if (isPalmPointing) {
			Globals.HAND_STATE_MANAGER.updateState('palm');
		} else {
			Globals.HAND_STATE_MANAGER.updateState(null);
		}

		Globals.HAND_STATE_MANAGER.checkState();
		if (Globals.STANDALONE !== true && Globals.HAND_STATE_MANAGER.getState() === 'palm') {
			if (Globals.HAND_STATE_MANAGER.getStateCount() === 0) {
				Globals.DOMCursor.changeToGoingBackToDemosCursor();
			}
		} else if (Globals.HAND_STATE_MANAGER.getState() === null) {
			if (Globals.HAND_STATE_MANAGER.getStateCount() === 0) {
				Globals.DOMCursor.changeToDefaultCursor();
			}
		}
	};

	public out = () => {
		Globals.PAUSE_HAND_DETECTION = true;
		gsap.to(this.element, 0.5, { y: -window.innerHeight, ease: 'power1.in', onComplete: this.outComplete });
		Globals.MAIN.hideBackToDemos();
	};

	kill() {
		this._drawingApp = null;
		super.kill();
	}
}
